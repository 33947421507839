const languageList = process.env.NEXT_PUBLIC_SITECORE_LANGUAGE_LIST?.split(',') || [];

export const envConfig = {
  sitecoreApiHost: process.env.SITECORE_API_HOST || '',
  disableSearchIndexing: process.env.NEXT_PUBLIC_DISABLE_SEARCH_INDEXING === 'true',
  qualtricsUrl: process.env.NEXT_PUBLIC_QUALTRICS_URL,
  endPointFlightBookingQueryHost: `${process.env.NEXT_PUBLIC_API_DOMAIN || ''}/flt-booking-query`,

  languageList,
  defaultLanguage:
    languageList.find((lang) => lang === process.env.NEXT_PUBLIC_DEFAULT_LANGUAGE) || '',
  defaultCurrency: process.env.NEXT_PUBLIC_DEFAULT_CURRENCY || 'HKD',

  publicUrl: process.env.NEXT_PUBLIC_PUBLIC_URL || '',

  bookingHost: process.env.NEXT_PUBLIC_BOOKING_HOST || '',

  akamaiClientSecret: process.env.AKAMAI_CLIENT_SECRET || '',
  akamaiClientToken: process.env.AKAMAI_CLIENT_TOKEN || '',
  akamaiAccessToken: process.env.AKAMAI_ACCESS_TOKEN || '',
  akamaiHost: process.env.AKAMAI_HOST || '',

  endpointContent:
    process.env.NEXT_PUBLIC_API_DOMAIN + '/non-pss-int/public/v1/content-management/list',

  endpointAdminHost: `${process.env.NEXT_PUBLIC_API_ADMIN_DOMAIN || ''}/admin`,
  adobeAnalyticScript: process.env.NEXT_PUBLIC_ADOBE_ANALYTIC_SCRIPT || '',
};
