import { Box, EPixel } from '@hkexpressairwayslimited/ui';
import { Grid } from '@mui/material';
import {
  Placeholder,
  useSitecoreContext,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

type ContainerProps = ComponentProps;

type directionType = 'row' | 'row-reverse' | 'column' | 'column-reverse';

const directionArr = ['row', 'row-reverse', 'column', 'column-reverse'];
const alignItemsArr = ['flex-start', 'center', 'flex-end', 'stretch', 'baseline'];
const justifyContentArr = [
  'flex-start',
  'center',
  'flex-end',
  'space-between',
  'space-around',
  'space-evenly',
];
const spacingArr = [0, 0.5, 1, 2, 3, 4, 8, 12];
const spacingValueObj = {
  0: 0,
  0.5: 4,
  1: 8,
  2: 16,
  3: 24,
  4: 32,
  8: 64,
  12: 96,
};

const Container = (props: ContainerProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const isPageEditing = sitecoreContext && sitecoreContext.pageEditing;
  const marginDesktop = props.params?.marginDesktop || 0;
  const paddingDesktop = props.params?.paddingDesktop || 0;
  const marginTablet = props.params?.marginTablet || 0;
  const paddingTablet = props.params?.paddingTablet || 0;
  const marginMobile = props.params?.marginMobile || 0;
  const paddingMobile = props.params?.paddingMobile || 0;
  const spacingDesktopValue = Number(props.params?.spacingDesktop) || 0;
  const spacingTabletValue = Number(props.params?.spacingTablet) || 0;
  const spacingMobileValue = Number(props.params?.spacingMobile) || 0;
  // const spacingValue = Number(props.params?.spacing);
  const direction = directionArr.includes(props.params?.direction)
    ? props.params?.direction
    : 'row';
  const alignItems = alignItemsArr.includes(props.params?.alignItems)
    ? props.params?.alignItems
    : '';
  const justifyContent = justifyContentArr.includes(props.params?.justifyContent)
    ? props.params?.justifyContent
    : '';
  // const spacing = spacingArr.includes(spacingValue) ? spacingValue : 0;
  const spacingDesktop = spacingArr.includes(spacingDesktopValue) ? spacingDesktopValue : 0;
  const spacingTablet = spacingArr.includes(spacingTabletValue) ? spacingTabletValue : 0;
  const spacingMobile = spacingArr.includes(spacingMobileValue) ? spacingMobileValue : 0;
  return (
    <Box
      sx={{
        padding: {
          md: paddingDesktop || paddingTablet || paddingMobile,
          sm: paddingTablet || paddingMobile,
          xs: paddingMobile,
        },
        margin: {
          md: marginDesktop || marginTablet || marginMobile,
          sm: marginTablet || marginMobile,
          xs: marginMobile,
        },
      }}
      className={`${props.params?.styles}`}
    >
      <Grid
        container
        spacing={{
          md: spacingDesktop || spacingTablet || spacingMobile,
          sm: spacingTablet || spacingMobile,
          xs: spacingMobile,
        }}
        direction={direction as directionType}
        alignItems={alignItems}
        justifyContent={justifyContent}
        sx={
          isPageEditing
            ? { padding: `${EPixel.px16} 0` }
            : {
                '&:has(> .MuiGrid-item) > div:not(.MuiGrid-item)': {
                  paddingLeft: {
                    md: `${
                      spacingValueObj[spacingDesktop as keyof typeof spacingValueObj] ||
                      spacingValueObj[spacingTablet as keyof typeof spacingValueObj] ||
                      spacingValueObj[spacingMobile as keyof typeof spacingValueObj]
                    }px`,
                    sm: `${
                      spacingValueObj[spacingTablet as keyof typeof spacingValueObj] ||
                      spacingValueObj[spacingMobile as keyof typeof spacingValueObj]
                    }px`,
                    xs: `${spacingValueObj[spacingMobile as keyof typeof spacingValueObj]}px`,
                  },
                },
              }
        }
      >
        <Placeholder name="container-placeholders" rendering={props.rendering} />
      </Grid>
    </Box>
  );
};

export default withDatasourceCheck()<ContainerProps>(Container);
